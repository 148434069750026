import APIKit from './Base'
import { logBackend } from './LoggingApi'

export interface KeywordDto{
    id?:number,
    name?:string
}

export const countKeywords = (name, idProduct) =>{
    return APIKit.get("/keyword/count", { params: { name: name, idProduct: idProduct } })
        .then<number>(response => {
            return response.data;
        })
        .catch(err => {
            throw(err)
        })
}

export const findKeywords = (limit, offset, name, idProduct) =>{
    return APIKit.get("/keyword", { params: { limit: limit, offset: offset, name: name, idProduct: idProduct } })
        .then<KeywordDto[]>(response => {
            return response.data;
        })
        .catch(err => {
            throw(err)
        })
}