import React, {useEffect, useState} from 'react'
import {Card, CardActions, CardContent, Paper, TextField, Typography} from "@material-ui/core";
import {ProductDto, findProductById, updateProduct, createProduct} from "../../api/ProductApi";
import {navigate} from "gatsby";
import ConfirmDialog from "../common/ConfirmDialog";
import {useForm} from "react-hook-form";

import { CompanyDto, findCompanies } from "../../api/CompanyApi";
import { useStyles } from "../../styles/CommonStyles";
import { useTranslation } from 'react-i18next';
import Header from "../common/Header";
import ButtonStyled from "../common/ButtonStyled";
import AutocompleteStyled from "../common/AutocompleteStyled";
import {KeywordDto, findKeywords} from "../../api/KeywordApi";
import BackendError from "../common/BackendError";
import InfoHover from "../common/InfoHover";

interface ProductEditProps{
    id: number,
    variant: "edit" | "create",
    style?: any
}
export default function ProductCreateEdit(props: ProductEditProps){
    const classes = useStyles();
    const { t, i18n } = useTranslation();

    const [product, setProduct] = useState<ProductDto>(null)
    const [companiesFull, setCompaniesFull] = useState<CompanyDto[]>([])
    const [keywordsFull, setKeywordsFull] = useState<KeywordDto[]>([])

    const [submitDialogOpen, setSubmitDialogOpen] = useState(false)
    const [backendErrorMessage, setBackendErrorMessage] = useState(null)
    const { register, handleSubmit, formState: { errors }, setValue } = useForm<ProductDto>();
    const nameRegister = register("name",{ required: true });
    const companyRegister = register("idCompany",{ required: true });
    const internalOrderNumberRegister = register("internalOrderNumber");
    const noteRegister = register("note");
    //const keywordsRegister = register("keywords");

    const onSubmit = handleSubmit(({name, idCompany, internalOrderNumber, note}) => {
        //if(keywords==undefined) keywords=[]
        if(props.variant=="edit"){
            updateProduct({id: product.id, manufacturer: product.manufacturer, name,idCompany,internalOrderNumber,note}).then((data) => {
                navigate("/products/"+data.id)
            }).catch(error=> {
                if(error.response!=undefined) {
                    setBackendErrorMessage(i18n.language=='cs' ? error.response.data.czechMessage!=null ? error.response.data.czechMessage : error.response.data.message : error.response.data.message);
                }else setBackendErrorMessage(t("ERRDATAERROR"));
                setSubmitDialogOpen(false)
            })
        }else{
            createProduct({ manufacturer: product.manufacturer, name,idCompany,internalOrderNumber,note}).then((data) => {
                navigate("/products/"+data.id)
            }).catch(error=>{
                if(error.response!=undefined) {
                    setBackendErrorMessage(i18n.language=='cs' ? error.response.data.czechMessage!=null ? error.response.data.czechMessage : error.response.data.message : error.response.data.message);
                }else setBackendErrorMessage(t("ERRDATAERROR"));
                setSubmitDialogOpen(false)
            })
        }

    })

    const handleManufacturerSelect = (value) => {
        console.log(value)
        if(value!=undefined){
            if(companiesFull.find(company=>company.name==value)==undefined){
                if(props.variant!="edit"){
                    if(companiesFull.length!=0){
                        setValue("idCompany",companiesFull[0].id )
                    }else{
                        setBackendErrorMessage(t("ERRNOCOMPANY"))
                    }
                }
            }else{
                setValue("idCompany",companiesFull.find(company=>company.name==value).id )
            }
        }else{
            setValue("idCompany",null )
        }
        product.manufacturer = value
        setProduct({...product})
    }
/*
    const handleKeywordSelect = (values) => {
        let tmpKeywords = []
        if(values!=undefined){
            values.forEach(val=>tmpKeywords.push({id:null,name:val}))
            setValue("keywords",tmpKeywords )
        }else{
            setValue("keywords",null )
        }
        product.keywords = tmpKeywords
        setProduct({...product})
    }
*/
    useEffect(() => {
        if(props.variant=="edit"){
            findProductById(props.id).then(data =>{
                setProduct(data)
                setValue("idCompany",data.idCompany)
                //setValue("keywords",data.keywords )
            }).catch(err => {
                console.log("Error getting product with id " + props.id, err)
                navigate("/404")
            })
        }else{
            setProduct({name:"", idCompany: null, internalOrderNumber: "", note: ""})
        }
        findCompanies(null,null,null).then(data => {
            setCompaniesFull(data);
        }).catch(err =>{
            console.log("Error getting all manufacturers")
        })
        findKeywords(null,null,null, null).then(data => {
            setKeywordsFull(data);
        }).catch(err =>{
            console.log("Error getting all keywords")
        })
    },[])

    return (
        <Paper className={classes.root} style={props.style}>
            {product!=null ?
                <Card variant="outlined">
                    <Header title={props.variant=="edit" ? t("PRODUCTEDIT") : t("PRODUCTCREATE")} titleTypographyProps={{variant:"h5", align:"center"}}/>
                    <CardContent>
                        {backendErrorMessage!=null && <BackendError error={backendErrorMessage}/>}
                        <Typography className={classes.fieldTitle} variant="h6">
                            {t("NAME")}
                        </Typography>
                        <TextField
                            fullWidth
                            className={classes.fieldContent}
                            style={errors.name && {marginBottom: 0}}
                            id="name"
                            type="text"
                            size="small"
                            placeholder={t("PRODUCTNAME")}
                            inputRef={nameRegister.ref}
                            onChange={nameRegister.onChange}
                            onBlur={nameRegister.onBlur}
                            name={nameRegister.name}
                            inputProps={{
                                defaultValue: product.name
                            }}
                        />
                        {errors.name && (<div className={`${classes.formErrors} ${classes.fieldContent}`}>{t("FILLPRODUCTNAME")}</div>)}
                        <Typography className={classes.fieldTitle} variant="h6">
                            {t("MANUFACTURERCOMPANY")} <InfoHover style={{display: "inline-block", verticalAlign: "middle"}} info={t("MANUFACTURERINFO")}/>
                        </Typography>
                        <AutocompleteStyled
                            label={t("SELECTMANUFACTURER")}
                            value={product.manufacturer}
                            className={classes.fieldContent}
                            style={errors.idCompany ? {width: "100%", marginBottom: 0} : {width: "100%"}}
                            multiple={false}
                            freeSolo={true}
                            options={companiesFull.map(manufacturer=>manufacturer.name)}
                            handleValueFunction={handleManufacturerSelect}
                            variant="standard"/>
                        {errors.idCompany && (<div className={`${classes.formErrors} ${classes.fieldContent}`}>{t("SELECTPRODUCTMANUFACTURER")}</div>)}
                        <Typography className={classes.fieldTitle} variant="h6">
                            {t("ION")}
                        </Typography>
                        <TextField
                            fullWidth
                            className={classes.fieldContent}
                            id="internalOrderNumber"
                            type="text"
                            size="small"
                            placeholder={t("IONPLACEHOLDER")}
                            inputRef={internalOrderNumberRegister.ref}
                            onChange={internalOrderNumberRegister.onChange}
                            onBlur={internalOrderNumberRegister.onBlur}
                            name={internalOrderNumberRegister.name}
                            inputProps={{
                                defaultValue: product.internalOrderNumber
                            }}
                        />
                        <Typography className={classes.fieldTitle} variant="h6">
                            {t("NOTE")}
                        </Typography>
                        <TextField
                            fullWidth
                            id="note"
                            className={classes.fieldContent}
                            type="text"
                            size="small"
                            placeholder={t("NOTEPLACEHOLDER")}
                            inputRef={noteRegister.ref}
                            onChange={noteRegister.onChange}
                            onBlur={noteRegister.onBlur}
                            name={noteRegister.name}
                            inputProps={{
                                defaultValue: product.note
                            }}
                        />
                        {/*
                        <Typography className={classes.fieldTitle} variant="h6">
                            Keywords <InfoHover style={{display: "inline-block", verticalAlign: "middle"}} info="If you want to create new keyword, write its name and hit enter."/>
                        </Typography>
                        <AutocompleteStyled
                            label="Select Keywords"
                            className={classes.fieldContent}
                            variant="standard"
                            style={{width: "100%"}}
                            value={product.keywords.map(keyword=>keyword.name)}
                            multiple={true}
                            freeSolo={true}
                            options={keywordsFull.map(keyword=>keyword.name)}
                            handleValueFunction={handleKeywordSelect}/>
                        */}
                    </CardContent>
                    <CardActions className={classes.cardActions}>
                        <ButtonStyled
                            style={{marginRight: "auto"}}
                            text={t("BACK")}
                            onClick={() => navigate(-1)}/>
                        <ButtonStyled
                            text={props.variant=="edit" ? t("SAVE") : t("CREATE")}
                            type="submit"
                            onClick={() => {
                                setSubmitDialogOpen(true)
                            }}/>
                        <ConfirmDialog
                            title={t("SAVECHANGES")}
                            open={submitDialogOpen}
                            onClose={()=>setSubmitDialogOpen(false)}
                            onConfirm={onSubmit}
                        >
                            {props.variant=="edit" ? t("SUBMITPRODUCT",{productName: product.name}) : t("CREATEPRODUCT")}
                        </ConfirmDialog>
                    </CardActions>
                </Card>
                : <div/>}
        </Paper>
    )
}