import APIKit from './Base'
import { logBackend } from './LoggingApi'
import { KeywordDto } from "./KeywordApi";

export interface ProductDto{
    id?:number,
    name?:string,
    idCompany?:number,
    manufacturer?:string,
    internalOrderNumber?:string,
    note?:string,
    lastChanged?:string,
    userChanged?:string,
}

export const countProducts = (name, idCompany, manufacturer, internalOrderNumber, keywords) =>{
    return APIKit.get("/product/count", { params: { name: name, idCompany: idCompany, manufacturer: manufacturer, internalOderNumber: internalOrderNumber, keywords: keywords.length!=0 ? keywords.join(",") : null } })
        .then<number>(response => {
            return response.data;
        })
        .catch(err => {
            throw(err)
        })
}

export const findProducts = (limit, offset, name, idCompany, manufacturer, internalOrderNumber, keywords) =>{
    return APIKit.get("/product", { params: { limit: limit, offset: offset, name: name, idCompany: idCompany, manufacturer: manufacturer, internalOderNumber: internalOrderNumber, keywords: keywords.length!=0 ? keywords.join(",") : null } })
        .then<ProductDto[]>(response => {
            return response.data;
        })
        .catch(err => {
            throw(err)
        })
}

export const findProductById = (id) =>{
    return APIKit.get("/product/"+id)
        .then<ProductDto>(response => {
            return response.data;
        })
        .catch(err => {
            throw(err)
        })
}

export const createProduct = (product: ProductDto) =>{
    return APIKit.post("/product", product)
        .then<ProductDto>(response => {
            return response.data;
        })
        .catch(err => {
            throw(err)
        })
}

export const updateProduct = (product: ProductDto) =>{
    return APIKit.put("/product/"+product.id, product)
        .then<ProductDto>(response => {
            return response.data;
        })
        .catch(err => {
            throw(err)
        })
}

export const deleteProductById = (id) =>{
    return APIKit.delete("/product/"+id)
        .then(response => {
            return response.status;
        })
        .catch(err => {
            throw(err)
        })
}